<template lang="html">
  <section class="view-offer">
    <div class="cabecera-oferta container">
      <h1>Tablón de Anuncios</h1>
    </div>

    <div class="container">
      <div class="descripcion">
        <div class="ofertas">
          <div v-for="(offer, key) in $tm('offers')" :key="key">
            <router-link
              :to="{
                name: 'oferta',
                params: {
                  slug: offer.slug,
                },
              }"
            >
              <section class="aifs row-between">
                <h4>{{ offer.title }}</h4>
                <span class="date">{{ offer.date }}</span>
              </section>
              <p>{{ offer.header }}</p>

              <p class="btn-bases -blue">LOREM IPSUM</p>
            </router-link>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

export default {
  name: 'view-offer',

  props: ['slug'],
  mounted() {

  },
  data() {
    return {
      activada: false,
    }
  },
  methods: {

  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
.view-offer {
}
</style>
